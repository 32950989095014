import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kim/Documents/MedGrocer/pp-jn-website/src/components/Layout/Layout.js";
import Container from "layout/Container";
import SEO from "layout/SEO";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container isCentered mdxType="Container">
      <SEO title="Patient Consent Form" mdxType="SEO" />
      <h2>{`Patient Consent Form`}</h2>
      <hr />
      <div className="content is-medium">
        <p>
  <span className="is-size-6">
    I have been prescribed medicines by my doctor and been provided professional
    advice on the diagnosis and treatment of my medical condition. I would like,
    however, to know more about the medicine and avail of patient care related
    services such as treatment schedule reminders and follow-up calls after drug
    administration, financial counselling on government financial assistance
    programs, and order placement with partner distributors and pharmacies.
    Therefore, I am voluntarily joining the CarePlus (“Program”) to help me
    manage my medical condition. This Program and my participation in this
    Program were clearly and thoroughly explained to me by my doctor and I
    understand that joining this Program may help me to achieve better health
    outcomes.
  </span>
        </p>
        <p>
  <span className="is-size-6">I understand that:</span>
        </p>
        <p>
          <span className="is-size-6">
            <ol>
              <li parentName="ol">{`MedGrocer is the administrator of the Program.`}</li>
              <li parentName="ol">{`MedGrocer’s Coordinators will provide me with more information on the mechanics and current benefits of the Program such as disease education, access, and safety support while I am enrolled in the Program.`}</li>
              <li parentName="ol">{`The MedGrocer Coordinator may collect, use, and process my personal information, including health and medical information, for my enjoyment of the benefits of the Program. I acknowledge that any information relayed to me by MedGrocer about my medical condition, the importance of therapy, method of administration, precautions and special instructions is for informative purposes only and not meant to replace the professional advice of my doctor.`}</li>
              <li parentName="ol">{`MedGrocer will collect, use, and process my personal information obtained with full and strict confidentiality in accordance with MedGrocer’s Privacy Policy set forth in this document, and in compliance with Republic Act No. 10173 or the “Data Privacy Act of 2012”, its Implementing Rules and Regulations, and the applicable issuances of the National Privacy Commission. I have the right to access, correct, update, and object to the processing of my personal information at any time by submitting a written request to the Data Protection Officer of MedGrocer and in appropriate cases, to lodge a complaint before the National Privacy Commission. For any data privacy related questions, comments, concerns, or complaints, you may contact MedGrocer’s Data Privacy Officer at dpo@medgrocer.com.`}</li>
              <li parentName="ol">{`The MedGrocer Coordinator will email, call, and send me text messages to provide reminders, support for my medical condition, and updates on the Program and I can contact the MedGrocer Coordinator to clarify any concerns about the Program and to correct any personal information I have given.`}</li>
              <li parentName="ol">{`I will comply with all guidelines of the Program to enjoy its benefits; otherwise, my enrollment in the Program may be terminated.`}</li>
              <li parentName="ol">{`The Program may be terminated upon service of 30 days prior notice through my email or mobile number.`}</li>
              <li parentName="ol">{`I acknowledge that I have been advised to immediately notify my doctor and arrange for medical consultation as may be required should I experience any adverse effects or side effects after using the medicine/s under the Program. Johnson & Johnson (Philippines), Inc. (“JJPI”), as the distributor of the medicines under the Program, and MedGrocer are obliged to collect details of any adverse events or product quality complaints that I may experience with the medicines included in the Program during the conduct of the Program and throughout my treatment. If an adverse event or product complaint is identified, I agree that JJPI and/or MedGrocer will collect this information from either me and/or my doctor for safety reporting and/or pharmacovigilance purposes only. I am aware that if I provide adverse event or product quality complaint information, it may be shared with regulatory agencies, JJPI, JJPI’s affiliates worldwide, and business partners with whom JJPI has contractual agreements for pharmacovigilance purposes only. JJPI may call my doctor and I consent to my doctor sharing personal information, including health and medical information, if more information is needed.`}</li>
              <li parentName="ol">{`I allow MedGrocer to disclose my personal information as required to, individual regulatory agencies or by applicable law, and, when circumstances warrant, transferred or transmitted to an authorized service provider only for the purpose of continuing the implementation of the CarePlus Program and to which the data subject is or has been enrolled.`}</li>
            </ol>
            <p>
  <span className="is-size-6">
    I hereby represent and warrant that all personal information that I have
    provided for/will provide in connection with my application for this Program
    is correct, accurate, and true. I confirm that my participation in this
    Program is voluntary and I am free to withdraw at any time based on my own
    decision or as recommended by my doctor and as such I will inform the
    Coordinator of my withdrawal by emailing{" "}
    <a href="mailto:careplus@medgrocer.com" className="has-text-primary has-text-weight-bold">
      careplus@medgrocer.com
    </a>
    .
  </span>
            </p>
          </span>
        </p>
      </div>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      